import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import './App.css';

import Home from './pages/home/Home';
import Lab from './pages/lab/Lab';

class App extends React.Component {
    render() {
        return (
            <Router>
                <Route exact path="/">
                    <Home />
                </Route>
                <Route path="/lab">
                    <Lab />
                </Route>
            </Router>        
        );
    }
}

export default App;
