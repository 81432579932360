import React from 'react';
import './Lab.css';

class Editor extends React.Component {    
    constructor(props) {
        super(props);
        
        this.state = {
            json: {}
        };
    }

    render() {
        const { state } = this;

        return (
            <div className="json-lab">
                <div>
                    A
                </div>
                <div>
                    B
                </div>                    
            </div>
        );
    }
}

export default Editor;
