import React from 'react';
import './JsonParser.css';
import './JsonParserTheme.css';

class JsonParser extends React.Component {
    static getDerivedStateFromProps(props, state) {
        if (props.json !== state.json) {
            return {
                json: props.json,
            };
        }
        return null;
    }
    
    constructor(props) {
        super(props);

        this.toggleObj = this.toggleObj.bind(this);

        this.state = {
            json: props.json,
            collapsed: {},
        };
    }

    toggleObj(e) {
        const { target } = e;
        const key = target.getAttribute('jkey');

        const { state } = this;

        state.collapsed[key] = !state.collapsed[key];
        this.setState({
            collapsed: state.collapsed,
        });
    }

    isCollaspsed(key) {
        const { state } = this;

        if (state.collapsed[key] === true) {
            return 'collapsed';
        }

        return 'expanded';
    }

    renderObject(obj, _key) {
        if (!obj) {
            return (
                <></>
            );
        }

        const keys = Object.keys(obj);
        const rows = [];

        for (let i = 0; i < keys.length; i +=1) {
            const row = [];
            const key = keys[i];
            const jkey = `${key}-${_key}`;
            
            row.push(
                <span className={`key`} onClick={this.toggleObj} jkey={jkey}>
                    {`"${key}"`}
                </span>,
            );

            if (obj[key] instanceof Array){
                row.push(
                    <span className="exp" onClick={this.toggleObj} jkey={jkey} />,
                    <span className="dots nohr">:</span>,
                    <span className="graph_a">{`[`}</span>,
                    <span className="arr_count">({obj[key].length})</span>,
                    this.JSONformatter(obj[key], key),
                    <span className="graph_a closeGraph">{`]`}</span>,
                );
            } else if (typeof obj[key] == 'object'){
                row.push(
                    <span className="exp" onClick={this.toggleObj} jkey={jkey}/>,
                    <span className="dots nohr">:</span>,
                    <span className="graph">{`{`}</span>,
                    <span className="arr_count">({Object.keys(obj[key]).length})</span>,
                    this.JSONformatter(obj[key], key),
                    <span className="graph closeGraph">{`}`}</span>,
                );
            } else {
                row.push(
                    <span className="dots">:</span>,
                    this.JSONformatter(obj[key], key),
                );                
            }

            if (i < keys.length - 1) {
                row.push(
                    <span className="comma nohr">,</span>,
                );
            }

            rows.push(
                <span className={`objEl ${this.isCollaspsed(jkey)}`}>{row}</span>,
            );
        }

        return (
            <>
                {rows}
            </>
        );
    }

    renderArray(arr) {
        const rows = [];

        for (var j = 0; j < arr.length; j++) {
            const row = [];

            if(arr[j] instanceof Array) {
                row.push(
                    <span className="graph_a">{`[`}</span>,
                    this.JSONformatter(arr[j]),
                    <span className="graph_a closeGraph">{`]`}</span>,
                );
            } else if(typeof arr[j] == 'object') {
                row.push(
                    <span className="graph">{`{`}</span>,
                    this.JSONformatter(arr[j]),
                    <span className="graph closeGraph">{`}`}</span>,
                );
            } else {
                row.push(
                    this.JSONformatter(arr[j]),
                );                
            }

            if (j < arr.length - 1) {
                row.push(
                    <span className="comma nohr">,</span>,
                );
            }
             
            rows.push(
                <span className="arrEl">{row}</span>,
            );
        }

        return (
            <>
                {rows}
            </>
        );        
    }

    JSONformatter(obj, _key){
        let type;
	 
	    if (typeof obj === 'string')
	        type = 1;
	    else if (typeof obj === 'number')
	        type = 2;
	    else if (obj === false || obj === true )
	        type = 5;
	    else if (obj === null)
	        type = 6;
	    else if (obj instanceof Array)
	        type = 4;
	    else
	        type = 3; // type object
	 
	    switch (type) {
        case 3: // type object
            return (
                <span className="obj">
                    {
                        this.renderObject(obj, _key)
                    }
                </span>
            );	 
        case 4: // type array
            return (
                <span className="array">
                    {
                        this.renderArray(obj, _key)
                    }
                </span>
            ); 	 
        case 2: // type number
            return (
                <span className="label n">
                    { obj }
                </span>
            ); 	 
        case 5: // type bool
            return (
                <span className="label bool">
                    { obj ? 'true' : 'false' }
                </span>
            );
        case 6: // type null
            return (
                <span className="label null">
                    { obj }
                </span>
            );	         
        default: // type string
            return (
                <span className="label">
                    "{obj}"
                </span>
            );
    	}
	}

    render() {
        const { state } = this;
        return (
            <>
                {
                    state.json !== {}
                    && (
                        <span className="obj">
                            <span className="graph">{`{`}</span>
                            {this.JSONformatter(state.json, 'first')}
                            <span className="graph closeGraph">{`}`}</span>
                        </span>
                    )
                }
            </>
        );
    }
}

export default JsonParser;
