import React from 'react';
import JsonParser from '../../components/json-parser/JsonParser';
import './Home.css';

import '../../components/saved-json/SavedJson.css';

class Home extends React.Component {    
    constructor(props) {
        super(props);

        this.rawInput = React.createRef();

        this.handleTxtChange = this.handleTxtChange.bind(this);
        this.handleTextInput = this.handleTextInput.bind(this);
        this.resizeTextarea = this.resizeTextarea.bind(this);
        this.parseJson = this.parseJson.bind(this);
        this.editJson = this.editJson.bind(this);
        this.saveJson = this.saveJson.bind(this);
        this.loadJson = this.loadJson.bind(this);
        this.deleteJson = this.deleteJson.bind(this);

        this.collapseSavedJson = this.collapseSavedJson.bind(this);

        this.state = {
            jsonName: `Untitled-${new Date().getTime()}`,
            storedJson: this.getSavedJson(),
            json: {},
            rawJson: '',
            error: '',
            view: 'editor',
            savedJsonCollapsed: true,
        };
    }

    handleTextInput(e) {
        const { target } = e;
        const { value } = target;
        const { name } = target;
        const { state } = this;

        state[name] = value;

        this.setState(
            {
                ...state,
            }
        );
    }

    handleTxtChange(e) {
        const { target } = e;
        const { value } = target;

        this.setState(
            {
                rawJson: value,
            }
        );
    }

    editJson() {
        this.setState({
            view: 'editor',
            error: '',
            json: {},
        }, () => {
            const target = this.rawInput.current;
            target.style.height = "";
            target.style.height = `${target.scrollHeight}px`;
        });
    }

    parseJson() {
        const { state } = this;
        if(state.view === 'editor') {
            try {
                const json = JSON.parse(state.rawJson);
                this.setState({
                    view: 'display',
                    error: '',
                    json,
                    savedJsonCollapsed: true,
                });
            } catch (e) {
                this.setState({
                    error: 'Invalid JSON',
                });
            }
        } else {
            this.setState({
                view: 'editor',
                error: '',
                json: {},
                rawJson: '',
            });
        }
    }

    getSavedJson() {
        const { localStorage } = window;
        let data = localStorage.getItem('data');
    
        try {
            data = JSON.parse(data) || {json: []};
        } catch (e) {
            data = {json: []};
        }

        return data;
    }

    saveJson(e) {
        const { state } = this;
        const { target } = e;
        const { localStorage } = window;

        let data = localStorage.getItem('data') || '{"json": []';
        const name = state.jsonName || `Untitled-${new Date().getTime()}`;

        try {
            data = JSON.parse(data);

        } catch (error) {
            data = {json: []};
        }

        data.json.push({name, string: state.rawJson.trim()});

        this.setState({
            storedJson: data,
            jsonName: `Untitled-${new Date().getTime()}`,
        }, () => {
            localStorage.setItem('data', JSON.stringify(data));
            target.setAttribute('disabled', 'true');
            setTimeout(() => {
                target.removeAttribute('disabled');
            }, 2000);
        });        
    }

    loadJson(e) {
        const { state } = this;
        const { target } = e;
        const name = target.innerText.trim();

        const data = this.getSavedJson();
        let jsonString = '';

        for (let i = 0; i < data.json.length; i += 1) {
            const json = data.json[i];
            if (json.name === name) {
                jsonString = json.string;
                break;
            }
        }

        if (state.view === 'display') {
            const json = JSON.parse(jsonString);
            this.setState({
                view: 'display',
                error: '',
                json,
            });            
        } else {
            this.setState(
                {
                    rawJson: jsonString,
                }, () => {
                    const target = this.rawInput.current;
                    target.style.height = "";
                    target.style.height = `${target.scrollHeight}px`;
                }
            );
        }
    }

    deleteJson(e) {
        const { target } = e;
        const name = target.getAttribute('json-name');

        const data = this.getSavedJson();

        for (let i = data.json.length - 1; i >= 0; i -= 1) {
            const json = data.json[i];
            if (json.name === name) {
                data.json.splice(i, 1);
                break;
            }
        }    
        
        this.setState({
            storedJson: data,
        }, () => {
            localStorage.setItem('data', JSON.stringify(data));
        });
    }

    collapseSavedJson() {
        this.setState(prevState => (
            {
                savedJsonCollapsed: !prevState.savedJsonCollapsed,
            }
        ));
    }

    printSavedJson() {
        const { state } = this;
        const data = state.storedJson;
        const fileList = [];
        
        for (let i = 0; i < data.json.length; i += 1) {
            const json = data.json[i];
            fileList.push(
                <li className="saved-json-btn">
                    <button className="saved-json-load" onClick={this.loadJson}>
                        {json.name}
                    </button>
                    <button className="saved-json-delete" onClick={this.deleteJson} json-name={json.name} />
                </li>,
            );
        }

        if (fileList.length === 0) {
            fileList.push(
                <li className="saved-json-btn no-data">
                    no saved jsons
                </li>,                
            );
        }

        return (
            <div className={`saved-json-panel ${state.savedJsonCollapsed ? 'collapsed' : ''}`}>
                <div className="saved-json-header">
                    <button type="button" onClick={this.collapseSavedJson} className="saved-json-close"/>
                    <button type="button" className="run" onClick={this.parseJson}>
                        {state.view === 'editor' ? 'RUN' : 'NEW'}
                    </button>                    
                </div>
                <ul className="saved-json-list">
                    {fileList}
                </ul>
            </div>
        );
    }

    resizeTextarea(e) {
        const { target } = e;
            
        target.style.height = "";
        target.style.height = `${target.scrollHeight}px`;
    }

    printJsonEditor() {
        const { state } = this;
        return (
            <div className={`json-editor`}>
                {this.printSavedJson()}
                <div className="json-input">    
                    <span className="error">{state.error}</span>                  
                    <textarea id="raw-json" onChange={this.handleTxtChange} value={state.rawJson} placeholder="Paste your JSON string here" onInput={this.resizeTextarea} ref={this.rawInput}></textarea>
                </div>
                <div className="site-description">
                    <h1>The Ultimate JSON beautifier.</h1>
                    <h2>
                        Beautify. Store. Serve<sup>1</sup>.
                    </h2>
                    {/* <p>
                        Beautify, edit<sup>1</sup> and store to your browser's local storage any JSON string. <br />
                    </p> */}
                </div>
            </div>            
        );
    }

    printJsonDisplay() {
        const { state } = this;
        return (
            <>
                <div>
                    {this.printSavedJson()}
                </div>
                <div className="json-display">
                    <div className="json-display-toolbar">
                        <input type="text" value={state.jsonName} name="jsonName" onChange={this.handleTextInput} />
                        <button className="save-json" onClick={this.saveJson} />
                    </div>
                    <div>
                        <JsonParser json={state.json} />
                    </div>
                </div>
            </>
        );
    }

    render() {
        const { state } = this;

        return (
            <>
                <header>
                    <div className="logo">
                        ultimateJSON <sup>BETA</sup>
                    </div>
                    <div className="actions">      
                        <div className="saved-json-tab">
                            <button className="show-saved-json" onClick={this.collapseSavedJson}>
                                open storage
                            </button>
                        </div>                                    
                        {
                            state.view === 'display'
                            && (
                                <button type="button" className="edit" onClick={this.editJson}>
                                    EDIT
                                </button>                                
                            )
                        }
                        <button type="button" className="run" onClick={this.parseJson}>
                            {state.view === 'editor' ? 'RUN' : 'NEW'}
                        </button>
                    </div>
                </header>
                <main>
                    {
                        state.view === 'editor'
                        && this.printJsonEditor()
                    }
                    {
                        state.view === 'display'
                        && this.printJsonDisplay()
                    }
                </main>
                <footer>
                    <div className="footnotes">
                        {
                            state.view === 'editor'
                            && '1. Feature not available yet.'
                        }
                    </div>
                    <div className="contacts">
                        Need new features? <a href="mailto:ultimatejson@altervista.org">Tell us</a> what you need!
                    </div>
                </footer>
            </>
        );
    }
}

export default Home;
